import React, { useContext } from 'react';
import { styled, keyframes } from 'styled-components';
import parse from 'html-react-parser';
import Btn from '../ui/Btn';
import useAnimateOnScroll from '../hooks/useAnimateOnScroll';

import { LanguageContext } from '../../context/languageContext';
import { translations } from '../../translations';
import { buttonContract } from '../../config';

const AboutSectionComponent = () => {
    const [setRef] = useAnimateOnScroll('active');

    const { language } = useContext(LanguageContext);
    const text = translations.aboutSect[language];

    return (
        <AboutSection className="sect about-sect g-box" id="about">
            <div className="container">
                <div className="rows">
                    <div className="title-box slideUp" ref={setRef}>
                        <h2 className="title sect-title align-center">{text ? parse(text.title) : ''}</h2>
                    </div>
                    <div className="columns g-box">
                        <div className="info-box g-box slideUp" ref={setRef}>
                            <div className="text-box">
                                {text ? parse(text.text) : ''}
                            </div>
                            <Btn type="link" href={buttonContract} target="_blank" className="big">{text?.buttonText || ''}</Btn>
                        </div>
                        <div className="images-box">
                            <img src="/img/meny/meny-2.svg" alt="Meny" className="meny"/>
                            <div className="coin first">
                                <img src="/img/coins/coin-1.svg" alt="MenyCoin" />
                            </div>
                            <div className="coin second">
                                <img src="/img/coins/coin-1.svg" alt="MenyCoin" />
                            </div>
                            <div className="bg">
                                <img src="/img/sections/about/about-bg.svg" alt="About bg" />
                            </div>
                            <div className="clouds">
                                <img src="/img/clouds/clouds2.svg" alt="Clouds" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="horn-box hornLeft" ref={setRef}>
                <img src="/img/horns/biven-right.svg" alt="horn" />
            </div>
        </AboutSection>
    )
};
  
export default AboutSectionComponent;

const coinAnim = keyframes`
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    20% {
        transform: translate(1px, -3px) rotate(2deg);
    }
    40% {
        transform: translate(-1px, -1px) rotate(-2deg);
    }
    60% {
        transform: translate(3px, 1px) rotate(3deg);
    }
    80% {
        transform: translate(-3px, 3px) rotate(-3deg);
    }
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
`;

const AboutSection = styled.div`
    &.about-sect{
        padding-top: 208px;
        position: relative;

        .horn-box{
            width: 388px;
            position: absolute;
            bottom: -100px;
            left: 0;
            z-index: 3;

            img{
                transform: scale(-1, 1);
            }
        }

        .text-box{
            font-size: 24px;
            font-weight: 700;
            line-height: 133%;
        }

        .columns{
            grid-template-columns: repeat(2, 1fr);
        }

        .info-box{
            max-width: 545px;
            grid-gap: 40px;
        }

        .images-box{
            text-align: right;
            position: relative;
            
            img.meny{
                max-width: 416px;
                margin-right: 108px;
                margin-top: -40px;
                z-index: 2;
                position: relative;
            }

            .bg{
                position: absolute;
                top: 0;
                right: 0;
                width: 739px;
                z-index: -1;
            }

            .coin{
                position: absolute;
                z-index: 1;                
                animation: ${coinAnim} 5s ease-in-out infinite alternate;
                transform: translate3d(0, 0, 0);

                &.first{
                    width: 130px;
                    top: 78px;
                    left: -55px;
                }

                &.second{
                    width: 100px;
                    top: 150px;
                    right: -4px;
                    animation-delay: 2s;

                    img{
                        transform: scale(-1, 1) rotate(-18deg);
                    }
                }
            }

            .clouds{
                width: 1440px;
                position: absolute;
                bottom: -120px;
                left: -55px;
            }
        }
    }

    @media (max-width: 1350px){
        &.about-sect{
        
            .images-box{
                img.meny{
                    max-width: 370px;
                    margin-right: 150px;
                }

                .bg{
                    width:100%;
                }

                .coin.first{
                    width: 115px;
                    left: -35px;
                }

                .coin.second {
                    top: 110px;
                    right: 7px;
                }

                .clouds{
                    left: 10px;
                }
            }
        }
    }

    @media (max-width: 1200px){
        &.about-sect{
            padding-top: 170px;

            .text-box{
                font-size: 22px;
            }

            .horn-box{
                width: 325px;
                bottom: -70px;
            }
        
            .images-box{
                .coin.first{
                    top: 70px;
                    left: -40px;
                }

                .coin.second{
                    top: 90px;
                }

                .clouds{
                    left: -20px;
                }

                img.meny {
                    margin-right: 110px;
                }
            }
        }
    }

    @media (max-width: 992px){
        &.about-sect{
            .text-box{
                font-size: 18px;
            }

            .horn-box{
                width: 250px;
                bottom: -80px;
            }

            .images-box{
                img.meny {
                    margin-right: 70px;
                    max-width: 245px;
                    margin-top: -20px;
                }

                .coin.second {
                    width: 68px;
                    top: 85px;
                }

                .coin.first {
                    top: 40px;
                    left: initial;
                    width: 76px;
                    right: 315px;
                }

                .clouds {
                    left: -50px;
                    width: 1200px;
                    bottom: -100px;
                }
            }
        }
    }

    @media (max-width: 768px){
        &.about-sect{
            padding-top: 60px;

            .horn-box{
                width: 112px;
                bottom: -45px;
            }

            .columns{
                grid-template-columns: 1fr;
            }

            .rows{
                grid-gap: 32px;
            }

            .title-box .title span{
                color: var(--yellow-color);
            }

            .text-box{
                font-size: 15px;
            }

            .btn{
                width: 100%;
            }

            .images-box{
                text-align: center;

                img.meny{
                    margin: 0;
                    max-width: 355px;
                    width:100%;
                }

                .coin.first {
                    width: 115px;
                    bottom: 30px;
                    left: -28px;
                    top: initial;
                    z-index: 2;
                }

                .coin.second {
                    width: 90px;
                    top: 6px;
                    right: -35px;
                }

                .clouds {
                    left: -10px;
                    width: 538px;
                    bottom: -33px;
                }

                .bg{
                    display: none;
                }
            }
        }
    
    }
`
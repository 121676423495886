import React, {useContext} from 'react';
import { styled } from 'styled-components';
import parse from 'html-react-parser';
import SocialList from '../ui/SocialList';
import useAnimateOnScroll from '../hooks/useAnimateOnScroll';

import { LanguageContext } from '../../context/languageContext';
import { translations } from '../../translations';

const JoinSectionComponent = () => {
    const [setRef] = useAnimateOnScroll('active');

    const { language } = useContext(LanguageContext);
    const text = translations.joinSect[language];

    return (
        <JoinSection className="sect join-sect g-box">
            <div className="container">
                <div className="join-box slideUp" ref={setRef}>
                    <div className="content g-box">
                        <div className="title-box">
                            <h3 className="title sect-title align-center">{text ? parse(text.title) : ''}</h3>
                        </div>
                        <SocialList telegram x instagram youtube/>
                    </div>
                </div>
            </div>
            <div className="horn-box hornLeft" ref={setRef}>
                <img src="/img/horns/biven-right.svg" alt="horn" />
            </div>
        </JoinSection>
    )
};
  
export default JoinSectionComponent;

const JoinSection = styled.div`
    &.join-sect{
        position: relative;

        .horn-box{
            width: 388px;
            position: absolute;
            bottom: -26px;
            left: 0;
            z-index: 3;
            
            img{
                transform: scale(-1, 1);
            }
        }

        .join-box{
            max-width:1064px;
            margin: 0 auto;
            padding: 119px 30px 110px;
            position: relative;

            .social-list{
                justify-content: center;
                gap: 40px;

                a{
                    width: 72px;
                    height: 78px;

                    img{
                        max-width:49px;
                    }
                }
            }

            &::before {
                content: '';
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                background-position: top;
                background-image: url(/img/sections/join/join-bg.png);
            }

            .content{
                position: relative;
                grid-gap: 32px;
            }
        }
    }

    @media (max-width: 1330px){
        &.join-sect{
            .horn-box{
                width: 290px;
            }
        }
    }

    @media (max-width: 992px){
        &.join-sect{
            .horn-box{
                width: 200px;
            }
        }
    }


    @media (max-width: 768px){

        &.join-sect{
            .horn-box{
                width: 112px;
                bottom: -35px;
            }
            .join-box{
                padding: 76px 20px 38px;

                &::before{
                    background-image: url(/img/sections/join/join-bg-mob.png);
                }

                .social-list{
                    gap: 16px;

                    a{
                        width: 64px;
                        height: 70px;

                        img{
                            max-width:48px;
                        }
                    }
                }

                .content{
                    grid-gap: 16px;
                }
            }
        }
            
        .ru &.join-sect{
            .title-box{
                .title{
                    font-size: 22px;
                }
            }
        }

        .nl &.join-sect,
        .de &.join-sect{
            .title-box{
                .title{
                    font-size: 25px;
                }
            }
        }
    }

    @media (max-width: 350px){
        &.join-sect{
            .join-box{
                .social-list{
                    a{
                        width: 50px;
                        height: 60px;
                    }
                }
            }
        }
        .ru &.join-sect{
            .title-box{
                .title{
                    font-size: 20px;
                }
            }
        }
    }
`
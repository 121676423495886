import React from 'react';
import ReactDOM from 'react-dom';
import { styled, keyframes } from 'styled-components';

const Notification = ({ show, children }) => {
    return ReactDOM.createPortal(
      <NotificationWrapper $show={show}>
        {children}
      </NotificationWrapper>,
      document.body
    );
};
export default Notification;

const NotificationWrapper = styled.div`
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 6666;
    padding: 16px 24px;
    display: grid;
    grid-gap: 10px;
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-align: center;
    border-radius: 16px;
    background: #006294;
    box-shadow: -4px 4px 0px 0px #010101;
    max-width: 216px;
    animation: ${({ $show }) => ($show ? fadeIn : fadeOut)} 0.2s ease forwards;
    
    img {
        max-width: 60px;
    }

    @media (max-width: 768px){
        padding: 10px 15px;
        font-size: 15px;
        max-width: 150px;
        right: 10px;

        img {
            max-width: 40px;
        }
    }

`

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const fadeOut = keyframes`
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(50px);
    }
`;  
import React, {useEffect, useState, useContext} from 'react';
import { styled } from 'styled-components';
import useWindowSize from "../hooks/windowResize";
import moment from 'moment';
import { timerTarget } from '../../config';

import { LanguageContext } from '../../context/languageContext';
import { translations } from '../../translations';

const targetTime = moment(timerTarget);
const formatNumber = (num) => num < 10 ? `0${num}` : num;

const Timer = () => {
    const [windowWidth] = useWindowSize();
    const [mob, setMob] = useState(false);
    const [isExpired, setIsExpired] = useState(false);

    const { language } = useContext(LanguageContext);
    const text = translations.timer[language];

    useEffect(() => {
        if(windowWidth < 768){
            setMob(true);
        } else {
            setMob(false);
        }
    }, [windowWidth]);

    const [currentTime, setCurrentTime] = useState(moment());
    const months = targetTime.diff(currentTime, 'months');
    const days = targetTime.diff(currentTime, 'days') % 30;
    const hours = targetTime.diff(currentTime, 'hours') % 24;
    const minutes = targetTime.diff(currentTime, 'minutes') % 60;
    const seconds = targetTime.diff(currentTime, 'seconds') % 60;

    useEffect(() => {
        const interval = setInterval(() => {
            const now = moment();
            setCurrentTime(now);
            if (now.isAfter(targetTime)) {
                setIsExpired(true);
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    if (isExpired) {
        return <StyledTimer><div className="time-up text-box align-center">{text?.timeUp || ''}</div></StyledTimer>
    } else {
        return (
            <StyledTimer className="f-box timer-block">
                <div className="timer-item g-box">
                    <span className="time">{formatNumber(months)}</span>
                    <span className="label">{text?.month || ''}</span>
                </div>
                <div className="timer-item g-box">
                    <span className="time">{formatNumber(days)}</span>
                    <span className="label">{text?.days || ''}</span>
                </div>
                <div className="timer-item g-box">
                    <span className="time">{formatNumber(hours)}</span>
                    <span className="label">{text?.hours || ''}</span>
                </div>
                <div className="timer-item g-box">
                    <span className="time">{formatNumber(minutes)}</span>
                    <span className="label">{mob ? (text?.minutesShort || '') : (text?.minutes || '')}</span>
                </div>
                <div className="timer-item g-box">
                    <span className="time">{formatNumber(seconds)}</span>
                    <span className="label">{mob ? (text?.secondsShort || '') : (text?.seconds || '')}</span>
                </div>
            </StyledTimer>
        );
    }
}
  
export default Timer;

const StyledTimer = styled.div`
    &.timer-block{
        gap: 24px;
        justify-content: center;

        .timer-item{
            grid-gap: 8px;
            text-align: center;

            .label{
                font-size: 15px;
                font-style: normal;
                font-weight: 800;
                line-height: 160%;
            }

            &:last-child{
                color: var(--yellow-color-dark);
            }

            .time{
                font-size: 48px;
                font-weight: 700;
                line-height: 125%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding: 8px;
                padding-bottom: 12px;
                --box-shadow-color: var(--yellow-color-dark);
                box-shadow: 0px -6px 0px 0px var(--box-shadow-color) inset;
                border: 1px solid var(--yellow-color-dark);
                background-color: #fff;
                border-radius: 8px;
                width: 100%;
                min-width:100px;
            }
        }

        .time-up{
            color: var(--red-color);
            padding: 40px 0;
        }
    }
    
    @media (max-width: 768px){
        &.timer-block{
            gap: 7px;

            .timer-item{
                .time{
                    font-size: 32px;
                    min-width: 60px;
                    padding: 8px 4px;
                    padding-bottom: 12px;
                }
            }

            .time-up{
                padding: 20px 0;
                font-size: 24px;
            }
        }

        .hu &.timer-block{
            .timer-item .label{
                font-size: 13px;
            }
        }
    }

    @media (max-width: 374px){
        &.timer-block{
            .timer-item{
                .time{
                    font-size: 24px;
                    min-width: 50px;
                }
            }
        }

        .hu &.timer-block{
            .timer-item .label{
                font-size: 11px;
            }
        }
    }

    @media (max-width: 330px){
        &.timer-block{
            .timer-item{
                .time{
                    padding:6px;

                    font-size: 24px;
                    min-width: 40px;
                }
            }
        }
    }
`
import React, {useState, useRef, useContext} from 'react';
import { styled } from 'styled-components';
import Btn from '../ui/Btn';
import Input from '../ui/Input';
import Notification from '../ui/Notification';
import parse from 'html-react-parser';

import { LanguageContext } from '../../context/languageContext';
import { translations } from '../../translations';

const CopyFieldComponent = ({value}) => {
    const textAreaRef = useRef(null);
    const [showNotification, setShowNotification] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    const { language } = useContext(LanguageContext);
    const text = translations.note[language];

    const copyToClipboard = () => {
        if (textAreaRef.current) {
            const text = textAreaRef.current.value;
            navigator.clipboard.writeText(text).then(() => {
                setIsVisible(true);
                setShowNotification(true);
                setTimeout(() => {
                    setShowNotification(false);
                    setTimeout(() => {
                        setIsVisible(false);
                    }, 200);
                }, 2000);
            }).catch(err => {
                alert('Failed to copy text: ', err);
            });
        }
    };

    return (
        <CopyField className="form-control copy-box">            
            <Input 
                type="text" 
                id="contract-adress"
                readOnly 
                htmlType="textarea"
                ref={textAreaRef}
                value={value}
            />
            <Btn type="button" onClick={copyToClipboard}>
                <img src="/img/copy-ico.svg" alt="copy-ico" className="ico"/>
            </Btn>
            {isVisible && (
                <Notification show={showNotification}>
                    <div className="info-popup" id="info-popup">
                        <div className="ico-box">
                            <img src="/img/check-ico.svg" alt="Check" />
                        </div>
                        <div>
                            <p>{text ? parse(text.message) : ''}</p>
                        </div>
                    </div>
                </Notification>
            )}
        </CopyField>
    );
}
  
export default CopyFieldComponent;

const CopyField = styled.div`
    &.copy-box{
        &.form-control{
            position: relative;
                    
            .bt-box{
                position: absolute;
                right: 4px;
                top: 4px;

                .btn{
                    padding: 8px;
                    z-index: 4;
                    position: relative;
                }
            }
        }

        textarea{
            width: 100%;
            padding-right: 62px;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;height: 56px;
            resize: none;
        }
    }        

    @media (max-width: 1024px){
        &.copy-box{
            textarea {
                height: auto;
                min-height: 48px;
                padding: 4px 16px;
                padding-right: 62px;
            }         

            .ico {
                margin-bottom: 7px;
            }
        }
    }

    @media (max-width: 330px){
        &.copy-box{
            textarea{
                height: 80px;
            }
        }
    }
`
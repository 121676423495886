import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider, useDisconnect } from '@web3modal/ethers5/react';
import { BrowserProvider, Contract, formatUnits, ethers } from 'ethers'
import {useState, useEffect, useCallback} from 'react';

const projectId = '1f6ccc6c4aa6e6d94031084bd211b38e';

const mainnet = {
  chainId: 42161,
  name: 'Arbitrum',
  currency: 'ETH',
  explorerUrl: 'https://arbiscan.io',
  rpcUrl: 'https://fabled-distinguished-owl.arbitrum-mainnet.quiknode.pro/546d1ee6bb9e4fb897c95135b1ec4ba3a73794c9',
};

const metadata = {
  name: 'Meny',
  description: 'Meny dApp',
  url: 'https://menycoin.com',
  icons: ['https://menycoin.com/favicon.png'],
};

const ethersConfig = defaultConfig({
  metadata,
  defaultChainId: 42161,
  enableEIP6963: true,
  rpcUrl: 'https://fabled-distinguished-owl.arbitrum-mainnet.quiknode.pro/546d1ee6bb9e4fb897c95135b1ec4ba3a73794c9',
});

createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
});

const USDTAbi = [
  'function name() view returns (string)',
  'function symbol() view returns (string)',
  'function balanceOf(address) view returns (uint)',
  'function transfer(address to, uint amount) returns (bool)',
  'function allowance(address owner, address spender) view returns (uint)',
  'function approve(address spender, uint amount) returns (bool)',
];
const USDCAbi = [
  'function name() view returns (string)',
  'function symbol() view returns (string)',
  'function balanceOf(address) view returns (uint)',
  'function transfer(address to, uint amount) returns (bool)',
  'function allowance(address owner, address spender) view returns (uint)',
  'function approve(address spender, uint amount) returns (bool)',
];

const USDTAddress = '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9';
const USDCAddress = '0xaf88d065e77c8cC2239327C5EDb3A432268e5831';

export default function useEthConnect() {
    const { open } = useWeb3Modal();
    const { address, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const { disconnect } = useDisconnect();

  const [selectedToken, setSelectedToken] = useState('ETH');
  const [balance, setBalance] = useState('');
  const [walletInfo, setWalletInfo] = useState({});

  const getWalletInfo = useCallback(async () => {
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = provider.getSigner();
    const walletAddress = await signer.getAddress();
    setWalletInfo({
      address: walletAddress,
    });
  }, [walletProvider]);

  const getBalance = useCallback(async () => {
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = provider.getSigner();
    let balance;

    if (selectedToken === 'ETH') {
      balance = await signer.getBalance();
      setBalance(ethers.utils.formatEther(balance));
    } else if (selectedToken === 'USDT') {
      const USDTContract = new Contract(USDTAddress, USDTAbi, signer);
      balance = await USDTContract.balanceOf(address);
      setBalance(ethers.utils.formatUnits(balance, 6));
    } else if (selectedToken === 'USDC') {
      const USDCContract = new Contract(USDCAddress, USDCAbi, signer);
      balance = await USDCContract.balanceOf(address);
      setBalance(ethers.utils.formatUnits(balance, 6));
    }
  }, [walletProvider, selectedToken, address]);

  useEffect(() => {
    if (isConnected) {
      getWalletInfo();
      getBalance();
    }
  }, [getBalance, getWalletInfo, isConnected]);

  async function sendTokens(amount, referralAddress) {
    try {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
      const contractAddress = '0x1f06D5926EeD5298B1872fe87A405d14c9d48bBf';

      if (!referralAddress || !referralAddress.startsWith('0x')) {
        referralAddress = '0x0000000000000000000000000000000000000000';
      }

      if (selectedToken === 'ETH') {
        const MENYContract = new Contract(contractAddress, ['function buyTokensWithETH(address referral) payable'], signer);
        const gasLimit = ethers.BigNumber.from('30000000');
        const tx = await MENYContract.buyTokensWithETH(referralAddress, { value: ethers.utils.parseEther(amount.toString()), gasLimit: gasLimit });
        console.log('ETH Transaction response:', tx);
        await tx.wait();

        return {
          id: 200,
          hash: tx.hash,
          link: 'https://arbiscan.io/tx/'
        };
      } else {
        const MENYContract = new Contract(contractAddress, ['function buyTokensWithERC20(address buyer, uint256 paymentAmount, address paymentTokenAddress, address referral)'], signer);
        const paymentTokenAddress = selectedToken === 'USDT' ? USDTAddress : USDCAddress;
        const paymentAmount = ethers.utils.parseUnits(amount.toString(), 6);

        // Проверка разрешения
        const tokenContract = new Contract(paymentTokenAddress, USDTAbi, signer);
        const allowance = await tokenContract.allowance(await signer.getAddress(), contractAddress);

        // Если разрешение недостаточно, одобряем контракт
        if (allowance.lt(paymentAmount)) {
          const txApprove = await tokenContract.approve(contractAddress, paymentAmount);
          console.log('Approval Transaction response:', txApprove);
          await txApprove.wait();
        }

        // Продолжаем выполнение транзакции
        const tx = await MENYContract.buyTokensWithERC20(await signer.getAddress(), paymentAmount, paymentTokenAddress, referralAddress);
        console.log('ERC20 Token Transaction response:', tx);
        await tx.wait();

        return {
          id: 200,
          hash: tx.hash,
          link: 'https://arbiscan.io/tx/' + tx.hash
        };
      }

    } catch (error) {
      console.error('Error during transaction:', error);
      return { id: 100, text: 'Transaction failed. See console for details.' };
    }
  }

  return {
    walletInfo,
    balance,
    selectedToken,
    setSelectedToken,
    sendTokens,
    disconnect,
    open,
    isConnected,
  }
}
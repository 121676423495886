import React, { useEffect, useState, useContext, useCallback } from 'react';
import Input from '../ui/Input';
import Select from '../ui/Select';
import Btn from '../ui/Btn';
import Popup from '../ui/Popup';
import CopyBox from '../parts/CopyField';
import Tooltip from '../ui/Tooltip';

import {
    optionsPayment,
    optionsCurrencySolana,
    optionsCurrencyEth,
    usdtRate
} from '../../config';
import { LanguageContext } from '../../context/languageContext';
import { translations } from '../../translations';
import useEthConnect from '../../connectWallets/useEthConnect';
import { useSolanaConnect } from '../../connectWallets/useSolanaConnect';
import { GetBinanceTicker } from '../../services/GetBinanceTicker';
import { debounce } from '../../helpers/debounce';
import ChatWidget from '../chatWidget/chatwidget';

const FormExchange = () => {
    const [walletConnected, setWalletConnected] = useState(optionsPayment[0].value);
    const [activeButton, setActiveButton] = useState(false);
    const [isVisiblePopup, setIsVisiblePopup] = useState(false);

    const { language } = useContext(LanguageContext);
    const text = translations.buySect[language];

    const [balance, setBalance] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [balanceTextTranslate, setBalanceTextTranslate] = useState('');

    const [recipientAddress, setRecipientAddress] = useState('');
    const [contractAddressTranslate, setContractAddressTranslate] = useState('');
    const [referralState, setReferralState] = useState('');

    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [amount, setAmount] = useState('')
    const [currency, setCurrency] = useState({value: '', label: '', icon: null})
    const [total, setTotal] = useState('')
    const [currentRate, setCurrentRate] = useState(0)

    const decimalsMapping = {
        usdt: 2,
        usdc: 2,
        eth: 6,
        sol: 4,
    };

    const getBinanceTicker = useCallback(async (symbol) => {
        const res = await GetBinanceTicker(symbol);
        if (res.status === 200 && res.data.lastPrice) {
            setCurrentRate(parseFloat((parseFloat(res.data.lastPrice) * usdtRate).toFixed(6)));
        }
    }, []);

    const fetchRateAndUpdateTotal = async (value, type) => {
        const decimals = decimalsMapping[currency.value] || 2;
        try {
            const response = await GetBinanceTicker(currency.value.toUpperCase())
            if (response.status === 200 && response.data.lastPrice) {
                const rate = parseFloat(response.data.lastPrice)
                setCurrentRate(rate);
                if(type === 'amount') {
                    if (value && parseFloat(value) > 0) {
                        setTotal(parseFloat((parseFloat(value) * (rate * usdtRate)).toFixed(decimals)))
                    }
                } else if(type === 'total'){
                    if (value && parseFloat(value) > 0) {
                        setAmount(parseFloat(((parseFloat(value) / (rate)) / usdtRate).toFixed(decimals)))
                    }
                }
            }
        } catch (error) {
            console.log('get current rate error');  
        }
    };

    const debouncedFetchRate = useCallback(
        debounce(fetchRateAndUpdateTotal, 500),
        [currency.value, currentRate]
    );

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const refid = urlParams.get('refid');
        if (refid) {
            setReferralState(refid);
        }
    }, []);

    const inputHandler = (e) => {
        const {name, value} = e.target
        const decimals = decimalsMapping[currency.value] || 2;
        if(name === 'amountToPay') {
            if (value) {
                const regex = new RegExp('^\\d*(\\.\\d{0,' + decimals + '})?$');
                let formattedValue = regex.test(value)
                if(formattedValue) {
                    setAmount(value);
                    if(currency.value !== 'usdt') {
                        debouncedFetchRate(value, 'amount');
                    } else {
                        setTotal(parseFloat((parseFloat(value) * parseFloat(usdtRate)).toFixed(6)));
                    }
                }
            } else {
                setAmount('')
                setTotal('')
            } 
        } else if(name === 'menyGet') {
            const regex = new RegExp('^\\d*(\\.\\d{0,6})?$');
            if(value) {
                let formattedValue = regex.test(value)
                if(formattedValue) {
                    setTotal(value);
                    if (parseFloat(value) > 0) {
                        if(currency.value !== 'usdt') {
                            debouncedFetchRate(value, 'total');
                        } else {
                            setAmount(parseFloat((parseFloat(value) / parseFloat(usdtRate)).toFixed(decimals)));
                        }
                    }
                }
            } else{
                setTotal('')
                setAmount('')
            }
        }
    }

    const handleChangeSelect = (e, name) => {
        if(name === 'payMethod'){
            setWalletConnected(e.value);
        }
    };

    const handleChangeCurrencySelect = (e) => {
        if(e.value === currency.value) return
        setTotal('')
        setAmount('')
        setCurrency({
            value: e.value,
            label: e.label,
            icon: e.icon
        });
    };

    // solana
    const {
        open: openSolWallet,
        isConnected: isSolConnected,
        walletAddress: walletAddressSolana,
        balance: balanceSolana,
        sendTransaction,
        disconnect: solDisconnect
    } = useSolanaConnect();
    //

    // eth
    const {
        walletInfo: ethWalletInfo,
        balance: ethBalance,
        selectedToken: ethSelectedToken,
        setSelectedToken: setEthSelectedToken,
        sendTokens: sendEthToken,
        disconnect: ethDisconnect,
        open: openEthWallet,
        isConnected: isEthConnected
    } = useEthConnect();
    //

    const handleConnection = async () => {
        if (walletConnected === 'eth') {
            openEthWallet();
        } else if (walletConnected === 'sol') {
            openSolWallet();
        }
    };

    const currentWalletHandler = () => {
        if (walletConnected === 'eth') {
            return isEthConnected ? true : false;
        } else if (walletConnected === 'sol') {
            return isSolConnected ? true : false;
        }
    };

    const handlePurchase = async () => {
        if (walletConnected === 'eth') {
            return sendEthToken(amount, referralState);
        } else if (walletConnected === 'sol') {
            return sendTransaction(amount);
        }
    };

    const handleDisconnect = async () => {
        if (walletConnected === 'eth') {
            ethDisconnect();
        } else if (walletConnected === 'sol') {
            solDisconnect();
        }
    };

    const handlePopup = () => {
        setIsVisiblePopup(prev => !prev);
    };

    useEffect(() => {
        if (walletConnected === 'eth') {
            if (currency.value === 'eth') {
                setEthSelectedToken('ETH');
            } else if (currency.value === 'usdt') {
                setEthSelectedToken('USDT');
            }
            setBalance(ethBalance);
            setWalletAddress(ethWalletInfo.address);
            setBalanceTextTranslate(text?.ethBalance);
            setContractAddressTranslate(text?.contractAdressEth);
        } else if (walletConnected === 'sol') {
            setBalance(balanceSolana);
            setWalletAddress(walletAddressSolana);
            setBalanceTextTranslate(text?.solBalance);
            setContractAddressTranslate(text?.contractAdressSol);
        }
    }, [walletConnected, isEthConnected, isSolConnected, ethBalance, balanceSolana, currency]);

    useEffect(() => {
        if (walletConnected === 'eth') {
            setBalanceTextTranslate(text?.ethBalance);
            setContractAddressTranslate(text?.contractAdressEth);
        } else if (walletConnected === 'sol') {
            setBalanceTextTranslate(text?.solBalance);
            setContractAddressTranslate(text?.contractAdressSol);
        }
    }, [language]);

    useEffect(() => {
        if(currencyOptions.length > 0) {
            setCurrency(currencyOptions[0])
        }
    }, [currencyOptions, walletConnected])

    useEffect(() => {
        setAmount('')
        setTotal('')
        if (walletConnected === 'sol') {
            setCurrencyOptions(optionsCurrencySolana);
            if(currencyOptions.length > 0) {
                setCurrency(currencyOptions[0])
            }
        } else if (walletConnected === 'eth') {
            setCurrencyOptions(optionsCurrencyEth);
            if(currencyOptions.length > 0) {
                setCurrency(currencyOptions[0])
            }
        }
    }, [walletConnected, currencyOptions])

    useEffect(() => {
        if(currency.value && currency.value !== 'usdt') {
            getBinanceTicker(walletConnected.toUpperCase(), currency.value)
        } else if(currency.value && currency.value === 'usdt'){
            setCurrentRate(usdtRate)
        }
    }, [currency])

    useEffect(() => {
        if (isVisiblePopup) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isVisiblePopup]);

    const isBuyDisabled = () => {
        if(!amount || !total) {
            return true
        } else if(currency.value === 'usdt' && parseFloat(amount) < 1) {
            return true
        } else if(currency.value !== 'usdt' && currentRate && (parseFloat(amount) * currentRate) < 1) {
            return true
        } else return false
    }

    useEffect(() => {
        setActiveButton(currentWalletHandler());
    }, [walletConnected, isEthConnected, isSolConnected]);

    useEffect(() => {
        if (walletConnected === 'eth') {
            setRecipientAddress(process.env.REACT_APP_ETH_WALLET_ADDRESS);
        } else if (walletConnected === 'sol') {
            setRecipientAddress(process.env.REACT_APP_SOL_WALLET_ADDRESS);
        }
    }, [walletConnected]);

    const customOption = (option) => (
        <div className="option-with-ico">
            {option.icon}
            {option.label}
        </div>
    );

    return (
        <>
            {/* <div className="form-group copy-group">
                <label>{contractAddressTranslate}</label>
                <CopyBox value={recipientAddress} />
            </div> */}
            {((isEthConnected && walletConnected === 'eth') || (isSolConnected && walletConnected === 'sol')) && (
                <div className="wallet-info">
                    <div>
                        <p>{balanceTextTranslate}: <b>{balance}</b></p>
                        <p>{text?.connectedAddress || ''}: {walletAddress && walletAddress?.slice(0, 6)}...{walletAddress && walletAddress?.slice(-4)}</p>
                    </div>
                    <div className="buttons-box">
                        <Tooltip tooltipText={text?.tooltipDisconnect || ''}>
                            <Btn type="button" className="white" onClick={handleDisconnect}>
                                <img src="/img/disconnect-ico.svg" alt="disconnect" />
                            </Btn> 
                        </Tooltip>
                    </div>
                </div>
            )}
            {/* <div className="align-center">
                <p>{text?.or || ''}</p>
            </div> */}
            <div className="form-box g-box">
                <div className="fields-list g-box">
                    <div className="form-group">
                        <label>{text?.selectCurrency || ''}</label>
                        <Select
                            name="currency"
                            id="currency"
                            options={currencyOptions}
                            value={currency}
                            getOptionLabel={customOption}
                            isSearchable={false}
                            placeholder={false}
                            closeMenuOnScrolls
                            onChange={e => handleChangeCurrencySelect(e)}
                        />
                    </div>
                    <div className="form-group">
                        <label>{text?.selectPayMethod || ''}</label>
                        <Select
                            name="payMethod"
                            id="payMethod"
                            options={optionsPayment}
                            defaultValue={optionsPayment[0]}
                            getOptionLabel={customOption}
                            isSearchable={false}
                            placeholder={false}
                            closeMenuOnScrolls
                            onChange={(e) => handleChangeSelect(e, 'payMethod')}
                        />
                    </div>
                    <div className="form-group">
                        <label>{currencyOptions.find(option => option.value === currency.value)?.label} {text?.youBuy || ''}</label>
                        <Input
                            type="number"
                            id="amountToPay"
                            name="amountToPay"
                            icon={currencyOptions.find(option => option.value === currency.value)?.icon.props.src}
                            value={amount}
                            onChange={e => inputHandler(e)}
                            placeholder=""
                            min="0"
                        />
                    </div>
                    <div className="form-group">
                        <label>
                            {text?.menyGet || ''}
                            <Tooltip 
                                tooltipText={text?.tooltipTGE || ''}
                            >
                                <span className="i-btn"></span>
                            </Tooltip>
                        </label>
                        <Input
                            type="number"
                            id="menyGet"
                            name="menyGet"
                            icon="/img/input-icons/meny-icon.svg"
                            value={total}
                            onChange={e => inputHandler(e)}
                            placeholder=""
                            min="0"
                        />
                    </div>
                        <div className="form-group refferal">
                            <label>{text?.referral || ''}</label>
                            <Input
                                type="text"
                                id="referralCode"
                                name="referralCode"
                                placeholder="Add"
                                value={referralState}
                                onChange={(e) => setReferralState(e.target.value)}
                            />
                        </div>
                </div>
                <div>
                    {activeButton ?
                        <Btn type="button" className="big" onClick={handlePopup} disabled={isBuyDisabled()}>{text?.textButtonBuy || ''}</Btn>
                        :
                        <Btn type="button" className="big" onClick={handleConnection}>{text?.textButtonConnect || ''}</Btn>
                    }
                </div>
                <div>
                    <ChatWidget />
                </div>
            </div>

            {isVisiblePopup && (
                <Popup
                    onClickBt={handlePurchase}
                    handlePopup={handlePopup}
                />
            )}
        </>
    );
};

export default FormExchange;
